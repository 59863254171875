import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { inject } from "@vercel/analytics";
import { Analytics } from "@vercel/analytics/react";

inject();

ReactDOM.render(
    <BrowserRouter>
        <App />
        <Analytics />
    </BrowserRouter>,
    document.getElementById("root")
);
