import React from "react";

function About() {
    return (
        <div className="lg:w-6/12 md:w-9/12 m-auto text-black font-['Montserrat'] leading-7 text-lg p-4 lg:p-0 md:p-0">
            <div>
                <h1 className="my-8 text-6xl font-bold">Invoice Build</h1>
                <h3 className="text-4xl my-12">Build, share, and manage invoices all within one app.</h3>
                <p className="mb-8">
                    Invoice Build is founded in 2023 by two tech enthusiast who used to face issues with invoice and
                    client management –{" "}
                    <a className="text-indigo-400" href="https://twitter.com/prathkum">
                        Pratham{" "}
                    </a>
                    and{" "}
                    <a className="text-indigo-400" href="https://twitter.com/iosmayank">
                        Mayank Gupta
                    </a>
                    . In the dynamic landscape of business, efficiency is key. Invoice Build is your comprehensive
                    solution, dedicated to simplifying the complexities of invoicing and client management. As a
                    forward-thinking startup, we recognize the importance of optimizing your workflow, and we've crafted
                    a robust tool to empower your business operations.
                </p>
                <p className="mb-8">
                    At Invoice Build, we are driven by a singular mission: to provide businesses of all sizes with an
                    intuitive, feature-rich platform that streamlines the invoicing process and elevates client
                    relationship management. We understand the demands of running a successful enterprise, and our tool
                    is designed to meet those demands head-on.
                </p>
                <div className="bg-indigo-400 p-8 lg:py-16 md:py-16 py-8 text-white flex justify-between rounded-xl my-16 lg:flex-row flex-col md:flex-row">
                    <p className="text-2xl text-center p-2 font-bold self-center">Invoice Build is 100% free! 🎉</p>
                    <a
                        href="https://invoicebuild.com"
                        className="bg-indigo-500 py-4 px-8 rounded-xl hover:bg-indigo-600 transition duration-150 ease-out hover:ease-in"
                    >
                        Get Started Today
                    </a>
                </div>
                <h3 className="text-4xl my-12">Key Features</h3>
                <h3 className="text-2xl mb-6">1. Streamlined Invoicing</h3>
                <p className="mb-12">
                    Effortlessly create polished, professional invoices tailored to your unique business needs. Our
                    user-friendly interface allows you to input essential details, customize layouts, and generate
                    invoices with unparalleled ease.
                </p>
                <h3 className="text-2xl mb-6">2. Seamless Sharing</h3>
                <p className="mb-12">
                    No more back-and-forth emails. Invoice Build lets you share invoices directly with your clients
                    through a secure and convenient platform. Experience a smoother communication process and ensure
                    that your clients receive and view invoices promptly.
                </p>
                <div className="bg-fuchsia-400 p-8 lg:py-16 md:py-16 py-8 text-white flex justify-between rounded-xl my-16 lg:flex-row flex-col md:flex-row">
                    <p className="text-2xl text-center p-2 font-bold self-center">In-Build mailing system! 🚀</p>
                    <a
                        href="https://invoicebuild.com"
                        className="bg-fuchsia-500 py-4 px-8 rounded-xl hover:bg-fuchsia-600 transition duration-150 ease-out hover:ease-in"
                    >
                        One Click Sharing
                    </a>
                </div>
                <h3 className="text-2xl mb-6">3. Client Management</h3>
                <p className="mb-12">
                    Organizing client information is a breeze with Invoice Build. Easily add, edit, and manage your
                    client database in one central location. Keep track of important details, communication history, and
                    outstanding invoices with our user-friendly client management features.
                </p>
                <h3 className="text-2xl mb-6">4. Dashboard for Insightful Analytics</h3>
                <p className="mb-12">
                    Gain a comprehensive overview of your financial landscape with our interactive Dashboard. Track
                    monthly amounts, due payments, and paid invoices at a glance. Monitor other important statistics to
                    make informed business decisions.
                </p>
                <div className="bg-green-400 p-8 lg:py-16 md:py-16 py-8 text-white flex justify-between rounded-xl my-16 lg:flex-row flex-col md:flex-row">
                    <p className="text-2xl text-center p-2 font-bold self-center">Intuitive finance dashboard! 💸</p>
                    <a
                        href="https://invoicebuild.com"
                        className="bg-green-500 py-4 px-8 rounded-xl hover:bg-green-600 transition duration-150 ease-out hover:ease-in"
                    >
                        Track Expenses
                    </a>
                </div>
                <h3 className="text-2xl mb-6">5. Features Tailored for You</h3>
                <p className="mb-6">
                    Invoice Build is equipped with all the essential features you need for personal invoicing and
                    finance management:
                </p>
                <ul className="list-disc mb-16 ml-2">
                    <li>
                        <span className="font-bold">Automatic Invoice Numbering: </span>Save time and reduce errors with
                        our automated invoice numbering system.
                    </li>
                    <li>
                        <span className="font-bold">Detailed Transaction Insights: </span>Gain valuable insights into
                        your financial transactions with comprehensive reporting tools.
                    </li>
                    <li>
                        <span className="font-bold">Personalization Options: </span>Customize your invoices to reflect
                        your unique style with templates and branding options.
                    </li>
                    <li>
                        <span className="font-bold">Payment Tracking: </span>Easily monitor payments and outstanding
                        balances for your personal projects.
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default About;
