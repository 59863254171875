import React from "react";
import "./index.css";
import Form from "./components/Form";
import Phone from "./components/Phone";
import Header from "./components/Header";
import { Routes, Route } from "react-router-dom";
import About from "./pages/About";
function App() {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <div className="flex flex-col h-screen">
                        <Header />
                        <div className="lg:grid md:grid block lg:grid-cols-2 md:grid-cols-2 gap-x-3 content-center lg:w-7/12 md:w-9/12 m-auto text-center md:text-left lg:text-left">
                            <div className="grid items-center m-8 lg:m-0 md:m-0">
                                <div>
                                    <p className="lg:text-7xl text-5xl font-bold">
                                        Build and Manage invoices with ease.
                                    </p>
                                    <Form />
                                </div>
                            </div>
                            <div className="flex lg:justify-end md:justify-end justify-center">
                                <Phone />
                            </div>
                        </div>
                    </div>
                }
            />
            <Route
                path="/about"
                element={
                    <>
                        <About />
                    </>
                }
            />
        </Routes>
    );
}

export default App;
