import React from "react";
import { useState } from "react";
import firebaseConfig from "../config";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, push } from "firebase/database";

const app = initializeApp(firebaseConfig);

const db = getDatabase();

function Form() {
    const [email, setEmail] = useState("");

    const addEmailToWaitlist = (email) => {
        const emailRef = ref(db, "users");
        const newEmailRef = push(emailRef);
        set(newEmailRef, email);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        addEmailToWaitlist(email);
        setEmail("");
    };

    return (
        <form className="mt-10" onSubmit={handleSubmit}>
            <label for="default-search" className="mb-2 text-sm font-medium text-black sr-only">
                Get early access
            </label>
            <div className="relative">
                <input
                    type="email"
                    id="default-search"
                    className="block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-black focus:border-black"
                    placeholder="alex@gmail.com"
                    required
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                />
                <button
                    type="submit"
                    className="text-white absolute right-2.5 bottom-2.5 bg-black hover:bg-zinc-950 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
                >
                    Get early access
                </button>
            </div>
        </form>
    );
}

export default Form;
