const firebaseConfig = {
    apiKey: process.env.API_KEY,
    authDomain: "invoice-build-8a115.firebaseapp.com",
    projectId: "invoice-build-8a115",
    storageBucket: "invoice-build-8a115.appspot.com",
    messagingSenderId: process.env.MESSAGE_SENDER_ID,
    appId: process.env.APP_ID,
    measurementId: "G-DBFP44M1X5",
};

export default firebaseConfig;
