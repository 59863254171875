import React from "react";
import { useState, useEffect } from "react";
import logo from "../assets/logo.jpeg";

function Header() {
    const [menuOpen, setMenuOpen] = useState(false);

    function toggleMenu() {
        setMenuOpen(!menuOpen);
    }

    function closeMenuOnScroll() {
        if (menuOpen) {
            setMenuOpen(false);
        }
    }

    // Add a scroll event listener to close the menu
    useEffect(() => {
        window.addEventListener("scroll", closeMenuOnScroll);

        return () => {
            window.removeEventListener("scroll", closeMenuOnScroll);
        };
    }, [menuOpen]);

    return (
        <header className="relative lg:flex justify-between lg:w-7/12 md:w-9/12 mt-4 mx-8 lg:mx-auto md:mx-auto">
            <div className="flex justify-between items-center">
                <div>
                    {/* <span className="text-2xl text-violet-600">InvoiceBuild</span> */}
                    <img className="h-8 cursor-pointer" src={logo} alt="Invoice Build logo" />
                </div>
                {/* Hamburger menu icon for smaller screens */}
                {/* Terneory operator */}

                <div className="lg:hidden">
                    <input type="checkbox" className="hidden" id="menu-icon" onChange={toggleMenu} checked={menuOpen} />
                    <label htmlFor="menu-icon" className="ease-in duration-300 cursor-pointer lg:hidden">
                        <svg
                            className="w-6 h-6 ease-in duration-300"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                className="ease-in duration-300"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                                d={menuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
                            ></path>
                        </svg>
                    </label>
                </div>
            </div>
            <div
                className={
                    "text-stone-400 absolute text-center " + (menuOpen ? "opacity-1 relative" : "invisible opacity-0")
                }
            >
                <a href="https://invoicebuild.com/about" className="block my-4">
                    Blog
                </a>
                <a href="https://invoicebuild.com/about" className="block">
                    About
                </a>
                <a href="#" className="block my-4">
                    Product updates
                </a>
            </div>

            {/* Buttons for larger and medium screens */}
            <div className="hidden lg:flex space-x-6 items-center text-stone-400">
                <a href="https://invoicebuild.com/about" className="">
                    Blog
                </a>
                <a href="https://invoicebuild.com/about" className="">
                    About
                </a>
                <a href="#" className="">
                    Product updates
                </a>
            </div>
        </header>
    );
}

export default Header;
