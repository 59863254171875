import React from "react";
import mockup from "../assets/app-mock.png";
import mockupDark from "../assets/app-mock-dark.png";
import mockupColor from "../assets/app-mock-color.png";
import mockupColor1 from "../assets/app-mock-color-1.png";
import mockupColor2 from "../assets/app-mock-color-2.png";

function Phone() {
    return (
        <div className="relative border-black bg-black border-[14px] rounded-[2.5rem] h-[600px] w-[300px] shadow-xl">
            <div className="w-[76px] h-[20px] bg-black top-[9px] rounded-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
            <div className="h-[46px] w-[3px] bg-black absolute -left-[17px] top-[124px] rounded-l-lg"></div>
            <div className="h-[46px] w-[3px] bg-black absolute -left-[17px] top-[178px] rounded-l-lg"></div>
            <div className="h-[64px] w-[3px] bg-black absolute -right-[17px] top-[142px] rounded-r-lg"></div>
            <div className="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white">
                {/* <img className="w-[272px] h-[572px]" src={mockup} alt="App mock up" /> */}
                {/* <img className="w-[272px] h-[572px]" src={mockupDark} alt="App mock up" /> */}
                {/* <img className="w-[272px] h-[572px]" src={mockupColor} alt="App mock up" /> */}
                {/* <img className="w-[272px] h-[572px]" src={mockupColor1} alt="App mock up" /> */}
                <img className="w-[272px] h-[572px]" src={mockupColor2} alt="App mock up" />
            </div>
        </div>
    );
}

export default Phone;
